import palette from '../palette';

export default {
  root: {
    padding: '0!important'
  },
  input: {
    padding: '10px 15px',
    fontSize: 15,
    lineHeight: '24px',
    height: 'initial'
  },
  notchedOutline: {
    border: 'none'
  },
  inputMultiline: {
    padding: '10px 15px',
  },
  adornedEnd: {
    // paddingRight: '30px!important'
  }
};
