import React from "react";
import {
	Box,
	Link,
	Typography
} from "@material-ui/core";
import {
	ExitToApp as ExitToAppIcon
} from "@material-ui/icons";
import {
	withStyles
} from "@material-ui/styles";
import {
	clearLocalStorage
} from "../../helpers/localStorage";
import {compose} from "recompose";
import {connect} from "react-redux";
import {setUser} from "../../AppState";

class Outdoor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {}
	}

	logOutUser = () => {
		this.props.setUser(null);
		clearLocalStorage('session_id');
	}

	render () {
		const {
			classes,
			children
		} = this.props;

		return (
			<Box className={classes.root}>
				<Box className={classes.head}>
					<Box className={classes.buttonLogOut}/>
					<Link className={classes.headLink}>
						<Typography>TC</Typography>
					</Link>
					<Box className={classes.buttonLogOut} onClick={this.logOutUser}>
						<ExitToAppIcon style={{color: "white"}}/>
					</Box>
				</Box>
				<Box className={classes.body}>
					{ children }
				</Box>
			</Box>
		)
	}
}

const styles = {
	root: {
		backgroundColor: "white"
	},
	head: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: 52,
		background: "black",
		position: "fixed",
		top: 0, left: 0, right: 0,
		zIndex: 20
	},
	headLink: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 52,
		padding: "0 24px",
		"& > *": {
			fontSize: 22,
			fontWeight: '600',
			color: 'white',
		}
	},
	body: {
		padding: "0 20px",
		paddingTop: 60,
		boxSizing: "border-box",
	},
	buttonLogOut: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: 52,
		height: 52,
	}
};
Outdoor = withStyles(styles)(Outdoor);

export default compose(
	connect(
		state => ({}),
		dispatch => ({
			setUser: (user) => dispatch(setUser(user))
		}),
	),
)(Outdoor)
