export default {
  indicator: {
    backgroundColor: '#212529',
    height: 3
  },
  flexContainer: {
    marginLeft: -32
  },


}
