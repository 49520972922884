import palette from '../palette';

export default {
  root: {
    fontSize: 16,
    lineHeight: '16px',
    padding: '10px 15px',
    height: 40,
    textTransform: 'initial',

    '&$formSubmit': {
      backgroundColor: '#28a745',
      color: 'white',

      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.26)'
      }
    },
    '&$formCancel': {
      backgroundColor: '#e53b37',
      color: 'white',

      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.26)'
      }
    }
  },

  contained: {
    boxShadow: 'none',
    backgroundColor: '#212529',
    color: 'white',
    padding: '10px 15px',

    '&:hover': {
      backgroundColor: '#212529',
      boxShadow: 'none',
    }
  },
  text: {
    height: 'auto',
    color: '#212529',
    textTransform: 'initial',
    fontWeight: 'normal',
  },
  outlined: {
    border: '1px solid ' + palette.gray.light,
    color: palette.gray.dark,
    padding: '10px 15px',
  },


  formSubmit: {},
  formCancel: {}
};
