import palette from '../palette';

export default {
  root: {
    backgroundColor: 'rgba(167, 172, 176, 0.2)',
    border: 'none',

    '&$focused': {
      boxShadow: '0 0 0 1px rgba(25, 42, 62, 0.1), 0px 2px 8px rgba(25, 42, 62, 0.1)',
      backgroundColor: "rgba(167, 172, 176, 0.2)"
    }
  }
};
