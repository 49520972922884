import {combineReducers} from 'redux';

import app from '../AppState';
import login from '../views/auth/Login/LoginState';

const rootReducer = combineReducers({
  app,
  login
});

export default rootReducer
