import palette from '../palette';

export default {
  root: {
    fontSize: 14,
    lineHeight: '18px!important',
    fontWeight: 'normal!important',
    color: palette.black + '!important',
    borderBottom: null
  }
};
