const compressImages = async (file) => {
	const type = "image/jpeg";
	const quality = 0.5;

	// Get as image data
	const imageBitmap = await createImageBitmap(file);

	// Draw to canvas
	const canvas = document.createElement('canvas');
	canvas.width = imageBitmap.width;
	canvas.height = imageBitmap.height;
	const ctx = canvas.getContext('2d');
	ctx.drawImage(imageBitmap, 0, 0);

	// Turn into Blob
	return await new Promise((resolve) =>
		canvas.toBlob(resolve, type, quality)
	);
}
export default compressImages
