import palette from '../palette';

export default {
  root: {
    '&.MuiTableHead-list': {
      '& > tr': {
        '& th': {
          padding: '15px 10px'
        }
      },
    },
    '& > tr': {
      backgroundColor: '#c2c4c7',
      boxShadow: 'initial',

      '& th': {
        padding: 15,
        color: '#212529!important'
      },
      '& th:first-child': {
        borderRadius: '4px 0 0 4px'
      },
      '& th:last-child': {
        borderRadius: '0 4px 4px 0'
      }
    },

    '&.MuiTableHead-list-modal': {
      '& > tr': {
        backgroundColor: 'rgb(218, 226, 236)',
      },
      '& .MuiTableCell-stickyHeader': {
        backgroundColor: 'rgb(218, 226, 236)',
      },
      '& > tr th': {
        padding: '5px',
        color: palette.gray.default + '!important'
      },
    }
  }
};
