import palette from '../palette';

export default {
  root: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: '500',
    color: palette.black
  },
  outlined: {
    transform: 'translate(15px, 15px) scale(1)'
  }
};
