import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import {RouteWithLayout} from '../components';
import {Main as MainLayout} from '../layouts';
import {Minimal as MinimalLayout} from '../layouts';

import {
    AuthLogin as AuthLoginView
} from '../views';

const pagesMain = [
    {
        path: '/',
        component: AuthLoginView,
        exact: true,
        layout: MinimalLayout,
    }
];

const Authorization = (props) => {
    return (
        <Switch>
            {
                pagesMain.map((page, idx) => (
                    <RouteWithLayout
                        key={'page-' + idx}
                        {...page}
                    />
                ))
            }

            <Redirect to="/"/>
        </Switch>
    );
};

export default Authorization
