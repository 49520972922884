import palette from '../palette';

export default {
  root: {},
  colorSecondary: {
    '&$checked': {
      color: palette.primary.hover,
    },
    '&$checked + .MuiSwitch-track': {
      backgroundColor: palette.primary.hover,
    },
  }
}
