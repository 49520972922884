import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import axios from '../../../plugins/axios';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTypography-root.MuiTypography-formTitle': {
      margin: 0
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: 0
    }
  }
}));

function LoginForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();

  const login = (form, setStatus, setErrors) => {
    axios('post', '/auth/login', form).then((response => {
      let data = response.data

      if (data.error){
        setStatus({ success: false });
        setErrors({ submit: data.error_text });

        return null
      }

      onSubmitSuccess(data)
    })).catch((error => {
      setStatus({ success: false });
      setErrors({ submit: error.response.data.message });
    }))
  }

  return (
    <Formik
      initialValues={{
        username: '',
        password: ''
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().max(255).required('Заполните поле'),
        password: Yup.string().max(255).required('Заполните поле')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus
      }) => {
        try {
          login(values, setStatus, setErrors)
        } catch (error) {
          const message = (error.response && error.response.data.message) || 'Что-то пошло не так';

          setStatus({ success: false });
          setErrors({ submit: message });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          autoComplete={'off'}
          {...rest}
        >
          <Box mt={2}>
            <Typography variant={'formTitle'}>Логин</Typography>
            <TextField
              fullWidth
              autoFocus
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
              margin="normal"
              name="username"
              onBlur={handleBlur}
              onChange={handleChange}
              type="username"
              value={values.username}
              variant="outlined"
            />
          </Box>
          <Box mt={2}>
            <Typography variant={'formTitle'}>Пароль</Typography>
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
          </Box>
          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Авторизоваться
            </Button>
            {errors.submit && (
              <Box mt={2}>
                <Alert severity="error">{errors.submit}</Alert>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};

LoginForm.defaultProps = {
  onSubmitSuccess: () => {}
};

export default LoginForm;
