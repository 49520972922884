import palette from '../palette';

export default {
  root: {
    position: 'relative',

    '&$formTitle': {
      color: palette.text.primary,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '500',
      display: 'block',
      marginBottom: 4
    },

    '& sup': {
      color: palette.primary.main,
      fontSize: 16,
      position: 'absolute',
      top: -5
    }
  },

  gutterBottom: {
    marginBottom: 8
  },
  formTitle: {}
};
