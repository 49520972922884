import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTable from './MuiTable';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableRow from './MuiTableRow';
import MuiTypography from './MuiTypography';
import MuiInputBase from './MuiInputBase';
import MuiInputLabel from './MuiInputLabel';
import MuiInput from './MuiInput';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiCheckbox from './MuiCheckbox';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiSvgIcon from './MuiSvgIcon';
import MuiBackdrop from './MuiBackdrop';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MuiSwitch from './MuiSwitch';

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTable,
  MuiTableCell,
  MuiTableRow,
  MuiTableHead,
  MuiTypography,
  MuiInputBase,
  MuiInputLabel,
  MuiInput,
  MuiOutlinedInput,
  MuiTableSortLabel,
  MuiCheckbox,
  MuiFormControlLabel,
  MuiSvgIcon,
  MuiBackdrop,
  MuiTab,
  MuiTabs,
  MuiSwitch,
};
