import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {ThemeProvider} from '@material-ui/styles';
import validate from 'validate.js';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import storeRedux from './redux/store';
import ReactNotification from 'react-notifications-component'
import {HashRouter} from 'react-router-dom'
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import Navigation from "./routes";
import MomentUtils from '@date-io/moment';
import 'react-notifications-component/dist/theme.css';

validate.validators = {
  ...validate.validators
};

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={storeRedux}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ReactNotification/>
            <HashRouter>
              <Navigation />
            </HashRouter>
          </MuiPickersUtilsProvider>
        </Provider>
      </ThemeProvider>
    );
  }
}
