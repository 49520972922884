import palette from '../palette';

export default {
  root: {
    '& .MuiIconButton-label': {
      '& svg': {
        backgroundColor: 'rgba(194, 207, 224, 0.2)',
        borderRadius: 4,
      },
      '& path': {
        display: 'none'
      }
    },
    '&$checked': {
      '& .MuiIconButton-label': {
        '& svg': {
          backgroundColor: palette.primary.main,
          stroke: palette.white,
          padding: 3
        },
        '& path': {
          display: 'block'
        }
      },
    }
  },
};
