export default {
  root: {
    '&.MuiTable-list': {
      borderCollapse: 'separate',
      borderSpacing: '0 8px'
    },
    '&.MuiTable-list-modal': {
      borderCollapse: 'separate',
      borderSpacing: '0 4px'
    }
  }
};
