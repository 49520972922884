import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import {RouteWithLayout} from '../components';
import {Outdoor as OutdoorLayout} from '../layouts';

import {
	Outdoor as OutdoorView
} from '../views';

const pagesMain = [
	{
		path: '/',
		exact: true,
		layout: OutdoorLayout,
		component: OutdoorView,
	}
];

const Authorization = (props) => {
	return (
		<Switch>
			{
				pagesMain.map((page, idx) => (
					<RouteWithLayout
						key={'page-' + idx}
						{...page}
					/>
				))
			}

			<Redirect to="/"/>
		</Switch>
	);
};

export default Authorization
