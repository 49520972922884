import React, { useState, useEffect } from "react";
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {getLocalStorage} from "../helpers/localStorage";
import {setUser} from "../AppState";
import { withRouter } from 'react-router-dom'

import Outdoor from "./Outdoor";
import Authorization from "./Authorization";
import axios from "../plugins/axios";
import {Box, CircularProgress, Typography} from "@material-ui/core";

const queryString = require('query-string');

const Routes = (props) => {
    const { app, history } = props;
    const { user } = app;

    const parsed = queryString.parse(window.location.search);

    const [isLoading, setLoading] = useState(true);
    const [search, setSearch] = useState(parsed.search ? parsed.search : '')

    useEffect(() => {
        ( async () => {
            await handleSetAccount();
        })();
    }, []);
    useEffect(() => {
        window.addEventListener('user_clear', handleChangeLocalStorage);
    }, []);
    useEffect(() => history.listen(() => {
        const isAccount = Boolean(localStorage.getItem('session_id'));
        if (isAccount) {
            return null
        }
        props.setUser({});
    }), [])

    const handleSetAccount = async () => {
        const sessionId = getLocalStorage('session_id');

        if (!sessionId) {
            setLoading(false);
            return false
        }

        const user = await axios('post', '/auth/session', {session_id: sessionId}).then((response) => {
            const data = response.data;
            if (!data.status) {
                return null
            }

            return data.user;
        }).catch((error) => {
            return null
        });

        props.setUser(user);
        setLoading(false);
    }
    const handleChangeLocalStorage = () => {
        console.log('handleChangeLocalStorage')
    }

    if (isLoading) {
        return (
            <PageLoading/>
        )
    }

    let Navigation = Outdoor;
    if (!user || !user?.id) {
        Navigation = Authorization;
    }
    if (!!user && !!user?.id && !user?.additional_roles?.outdoor) {
        Navigation = NotAllowed;
    }

    return (
        <Navigation/>
    )
}
const PageLoading = () => {
  return (
    <Box bgcolor="#f4f6f8" height="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">

        <Box mb={5}>
            <CircularProgress size={160} thickness={2} style={{color: '#192A3E'}}/>
        </Box>

        <Typography variant="h4">Идет загрузка, ожидайте...</Typography>

    </Box>
  )
};
const NotAllowed = () => {
    return (
      <Box bgcolor={'white'} p={3} borderRadius={4}>
          <Box mb={2}>
              <Typography variant={'h2'} align={'center'}>Доступ запрещен</Typography>
          </Box>

          <Typography variant={'h4'} align={'center'}>
              У вас не достаточно прав для просмотра данного раздела
          </Typography>
      </Box>
    )
}


const RoutesRouter = withRouter(Routes);

export default compose(
    connect(
        state => ({
            app: state.app
        }),
        dispatch => ({
            setUser: (user) => dispatch(setUser(user))
        }),
    ),
)(RoutesRouter);
