export default {
  root: {
    backgroundColor: 'rgba(24, 31, 72, 0.5)'
  },

  invisible: {
    backgroundColor: 'rgba(24, 31, 72, 0.5)',
    zIndex: 99999999
  }
};
