const UPDATE_USER = 'app/UPDATE_USER'

const initialState = {
  user: {}
};

export function setUser(user) {
  return {
    type: UPDATE_USER,
    user
  }
}

// Reducer
export default function AppState(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_USER: {
      let user = action.user

      return {
        ...state,
        user
      }
    }
    default:
      return state;
  }
}
